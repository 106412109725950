// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-e-ticket-jsx": () => import("./../../../src/pages/e-ticket.jsx" /* webpackChunkName: "component---src-pages-e-ticket-jsx" */),
  "component---src-pages-generator-link-jsx": () => import("./../../../src/pages/generator-link.jsx" /* webpackChunkName: "component---src-pages-generator-link-jsx" */),
  "component---src-pages-generator-qr-jsx": () => import("./../../../src/pages/generator-qr.jsx" /* webpackChunkName: "component---src-pages-generator-qr-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

